import { gql } from '@apollo/client';
import { SCHEDULE_FRAGMENT } from '../../videos/graphql/Queries';

export const GET_FORMS = gql`
  query FormsAdmin($filter: FormsFilter, $sort: FormsSort) {
    formsAdmin(filter: $filter, sort: $sort) {
      count
      forms {
        id
        description
        status
        title
        type
        slug
      }
    }
  }
`;

export const GET_FORM_TEMPLATE = gql`
  query FormTemplateAdmin($where: FormTemplateWhereUniqueInput!) {
    formTemplateAdmin(where: $where) {
      messages {
        type
        message
      }
      ctas {
        label
        type
        action
        actionUrl
        icon {
          id
          title
        }
      }
      fields {
        key
        label
        type
        required
        order
        options {
          key
          label
          order
        }
      }
      type
    }
  }
`;

export const GET_FORM = gql`
  ${SCHEDULE_FRAGMENT}
  query FormAdmin($where: FormWhereUniqueInput!) {
    formAdmin(where: $where) {
      id
      title
      slug
      description
      type
      status
      fields {
        key
        label
        type
        required
        order
        options {
          key
          label
          order
        }
      }
      ctas {
        label
        type
        action
        actionUrl
        icon {
          id
          url
        }
      }
      permissions
      metaData
      metaHeader
      metaFooter
      messages {
        type
        message
      }
      createdBy {
        id
        firstName
        lastName
      }
      order
      html
      allowedPlans {
        key
        expiredAt
      }
      isScheduled
      schedule {
        ...ScheduleFragment
      }
    }
  }
`;

export const FORM_RESPONSES = gql`
  query FormSubmissions($filter: FormSubmissionsFilter!) {
    formSubmissionsAdmin(filter: $filter) {
      formSubmissions {
        formId
        id
        submission
        submitter {
          id
          firstName
          email
          lastName
        }
      }
    }
  }
`;

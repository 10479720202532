import { useMutation } from '@apollo/client';
import { Button, Tooltip } from 'antd';
import { Export } from 'phosphor-react';
import React, { useMemo, useState } from 'react';
import {
  LIST_TYPES,
  MODULES,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION
} from '../../common/constants';
import FilterBar, { useFilterBar } from '../../components/FilterBar';
import PageHeader from '../../components/PageHeader';
import PageList from '../../components/PageList';
import useCheckPermission from '../../hooks/useCheckPermission';
import { REQUEST_FORM_RESPONSE_EXPORT } from './graphql/Mutations';
import { FORM_RESPONSES } from './graphql/Queries';

const variablesSelector = ({ offset, limit, formId }) => ({
  filter: {
    skip: offset,
    limit,
    formId
  }
});

const dataSelector = (data) => ({
  data: data?.formSubmissionsAdmin?.formSubmissions ?? [],
  count: data?.formSubmissionsAdmin?.count ?? 0
});

const FormResponses = ({ match: { params } }) => {
  const [keys, setKeys] = useState([]);
  const filterProps = useFilterBar();
  const { formId } = params;

  const [request, { loading: isExporting }] = useMutation(
    REQUEST_FORM_RESPONSE_EXPORT,
    {
      onError() {},
      variables: {
        where: {
          id: formId
        }
      }
    }
  );

  const columns = useMemo(() => {
    return [
      ...keys.map((key) => ({
        title: key,
        dataIndex: key,
        ellipsis: true,
        render: (_, record) => record?.submission?.[key] || '-'
      })),
      ...(keys.length
        ? [
            {
              title: 'Submitted By',
              dataIndex: 'submittedBy',
              ellipsis: true,
              render: (_, record) => {
                const { firstName = '', lastName = '' } =
                  record?.submitter || {};
                return `${firstName} ${lastName}`.trim() || '-';
              }
            }
          ]
        : [])
    ];
  }, [keys]);

  const filters = useMemo(
    () => ({
      formId
    }),
    [formId]
  );

  const isExportAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.REPORT_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  const handleExport = () => {
    request();
  };

  const handleLoadData = (data) => {
    if (data?.length > 0) {
      const record = data?.[0]?.submission;
      setKeys(Object.keys(record));
    }
  };

  return (
    <>
      <PageHeader menu={MODULES.CONTENT_UNITS} />
      <FilterBar
        {...filterProps}
        show={{
          listModes: false,
          search: false,
          sort: false
        }}
        SearchProps={{ placeholder: 'Search Users' }}
      >
        {isExportAllowed && (
          <Tooltip title="Export Form Responses" placement="left">
            <Button
              className="text-btn d-flex align-center justify-center"
              type="text"
              icon={<Export size={24} />}
              onClick={handleExport}
              loading={isExporting}
            />
          </Tooltip>
        )}
      </FilterBar>
      <PageList
        filters={filters}
        listMode={LIST_TYPES.TABLE}
        query={FORM_RESPONSES}
        variablesSelector={variablesSelector}
        dataSelector={dataSelector}
        TableProps={{
          columns
        }}
        limit={20}
        onLoad={handleLoadData}
      />
    </>
  );
};

export default FormResponses;

import { useLazyQuery } from '@apollo/client';
import { Form } from 'antd';
import { flattenDeep, groupBy, map, uniqBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import PageHeader from '../../components/PageHeader';
import LayoutRow from '../component/layout/LayoutRow';
import { Switch } from '../pages/component/pageModules/moduleForms/FormInputs';
import LeftPanelPermissions from './components/LeftPanelPermissions';
import MiddlePanelPermissions from './components/MiddlePanelPermissions';
import RightPanelPermissions from './components/RightPanelPermissions';
import { SUBSCRIPTION_PERMISSIONS } from './graphql/Queries';

const EditPermissions = ({ match: { params } }) => {
  const { planId } = params;
  const [form] = Form.useForm();
  const [permissions, setPermissions] = useState([]);
  const [moduleKey, setModuleKey] = useState('');
  const [permissionKey, setPermissionKey] = useState('');
  const [allPermissions, setAllPermissions] = useState([]);
  const [permissionsValues, setPermissionsValues] = useState({});
  const [allowedPermission, setAllowedPermission] = useState(false);
  const [permissionsLoading, setPermissionLoading] = useState(false);

  const [fetchSubscriptionPermissions, { data, loading }] = useLazyQuery(
    SUBSCRIPTION_PERMISSIONS,
    {
      fetchPolicy: 'network-only',
      onCompleted(res) {
        const groupPermissions = groupBy(
          res?.subscriptionPlanPermissions,
          'group'
        );
        const updatedPermissions = Object.values(groupPermissions);
        setPermissions(uniqBy(flattenDeep(updatedPermissions), 'group'));
        setModuleKey(
          uniqBy(flattenDeep(updatedPermissions), 'group')?.[0]?.group
        );
        let updateValues = {};
        setAllPermissions(res?.subscriptionPlan?.permissions);
        map(res?.subscriptionPlanPermissions, (item) => {
          updateValues = {
            ...updateValues,
            [item?.key]: {
              allowed: true,
              applyLimit: false,
              limit: 0,
              frequency: 0,
              errorMessage: null,
              limitMessage: null,
              permissionType: item?.type,
              errorTitle: null,
              limitTitle: null
            }
          };
        });
        setPermissionsValues(updateValues);
      },
      onError() {}
    }
  );

  const permissionData = groupBy(data?.subscriptionPlanPermissions, 'group')?.[
    moduleKey
  ];

  const onChange = (checked) => {
    setAllowedPermission(checked);
  };

  useEffect(() => {
    fetchSubscriptionPermissions();
  }, []);

  useEffect(() => {
    if (moduleKey) {
      setPermissionKey(permissionData?.[0]?.key);
    }
  }, [moduleKey]);

  return (
    <>
      <PageHeader
        isAllowPermission
        setAllowedPermission={setAllowedPermission}
        allowedPermission={allowedPermission}
      >
        <Switch
          onChange={onChange}
          label="Apply Permissions"
          reverse
          checked={allowedPermission}
        />
      </PageHeader>
      <div className="page-wrapper">
        <div className="page-wrapper-body edit-page">
          <LayoutRow>
            <LeftPanelPermissions
              moduleKey={moduleKey}
              setModuleKey={setModuleKey}
              form={form}
              permissions={permissions}
              loading={loading}
              permissionsLoading={permissionsLoading}
            />

            <MiddlePanelPermissions
              permissionKey={permissionKey}
              setPermissionKey={setPermissionKey}
              form={form}
              permissions={permissionData}
              loading={loading}
              permissionsLoading={permissionsLoading}
            />

            <RightPanelPermissions
              form={form}
              loading={loading}
              permissionKey={permissionKey}
              planId={planId}
              allPermissions={allPermissions}
              setAllPermissions={setAllPermissions}
              allowedPermission={allowedPermission}
              permissionsValues={permissionsValues}
              setPermissionsValues={setPermissionsValues}
              setAllowedPermission={setAllowedPermission}
              permissionsLoading={permissionsLoading}
              setPermissionLoading={setPermissionLoading}
            />
          </LayoutRow>
        </div>
      </div>
    </>
  );
};

export default EditPermissions;

import React from 'react';
import { Route, Switch } from 'react-router-dom';
import {
  ROUTES,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION
} from '../../../common/constants';
import AccessControl from '../../../components/AccessControl';
import Comment from './Comment';
import Comments from './Comments';

const CommentsWrapper = () => {
  return (
    <Switch>
      <Route
        exact
        path={ROUTES?.COMMENTS}
        render={(props) => (
          <AccessControl
            modulePermissions={[
              {
                moduleKey: WORKSPACE_ROLE_PERMISSION.CONTENT_MANAGEMENT,
                allowedPermissions: [
                  WORKSPACE_ROLE_LEVEL.VIEW,
                  WORKSPACE_ROLE_LEVEL.EDIT,
                  WORKSPACE_ROLE_LEVEL.DELETE
                ]
              }
            ]}
          >
            <Comments {...props} />
          </AccessControl>
        )}
      />
      <Route
        exact
        path={`${ROUTES?.COMMENTS}/:id`}
        render={(props) => (
          <AccessControl
            modulePermissions={[
              {
                moduleKey: WORKSPACE_ROLE_PERMISSION.CONTENT_MANAGEMENT,
                allowedPermissions: [
                  WORKSPACE_ROLE_LEVEL.VIEW,
                  WORKSPACE_ROLE_LEVEL.EDIT,
                  WORKSPACE_ROLE_LEVEL.DELETE
                ]
              }
            ]}
          >
            <Comment {...props} />
          </AccessControl>
        )}
      />
    </Switch>
  );
};

export default CommentsWrapper;

import {
  CommentOutlined,
  EyeOutlined,
  FlagOutlined,
  LikeOutlined
} from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Avatar, Flex, Tooltip } from 'antd';
import { isFunction } from 'lodash';
import moment from 'moment';
import React, { useMemo, useRef } from 'react';
import {
  COMMENTS_INSTANCE_TYPES,
  ROUTES,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION
} from '../../../../common/constants';
import {
  getFeatureArticleData,
  getFeaturePodcastData,
  getFeatureVideoData,
  getFeaturedFormData
} from '../../../../common/utils';
import AccessControl from '../../../../components/AccessControl';
import FeatureArticleCard from '../../../component/articleModules/FeatureArticleCard';
import FeaturedPodcastCarousel from '../../../component/featuredModules/FeaturedPodcastCarousel';
import FeaturedCarousel from '../../../component/featuredModules/FeaturedVideoCarousel';
import FormDisplay from '../../../component/formModules/FormDisplay';
import { CheckBox } from '../../../pages/component/pageModules/moduleForms/FormInputs';
import { UPDATE_POST } from '../graphql/Mutations';
import Attachment from './Attachment';
import Count from './Count';
import ModerationTags from './ModerationTags';

export const VISIBILITY_TYPES = {
  ALL: 'All',
  VISIBLE: 'VISIBLE',
  HIDDEN: 'HIDDEN'
};

function HandsPraying() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
      <rect width="256" height="256" fill="none" />
      <path
        d="M141.78,16h0A13.77,13.77,0,0,1,155,25.78L192,148l20.27,20.27-45,43-29.94-29.94A32,32,0,0,1,128,158.75v-129A13.78,13.78,0,0,1,141.78,16Z"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="16"
      />
      <path
        d="M167.31,211.31l18.35,18.35a8,8,0,0,0,11.31,0L229.66,197a8,8,0,0,0,0-11.31l-18.35-18.35"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="16"
      />
      <path
        d="M118.63,181.37,88.69,211.31l-44-44L64,148,101,25.78A13.77,13.77,0,0,1,114.22,16h0A13.78,13.78,0,0,1,128,29.78v129A32,32,0,0,1,118.63,181.37Z"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="16"
      />
      <path
        d="M44.69,167.31,26.34,185.66a8,8,0,0,0,0,11.31L59,229.66a8,8,0,0,0,11.31,0l18.35-18.35"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="16"
      />
    </svg>
  );
}

function PostInstance({ type, data }) {
  const Component = {
    VIDEO: FeaturedCarousel,
    PODCAST: FeaturedPodcastCarousel,
    ARTICLE: FeatureArticleCard,
    FORM: FormDisplay,
    POST: PostCard
  }[type];

  const componentProps = useMemo(
    () =>
      ({
        VIDEO: {
          data: [getFeatureVideoData(data)],
          showCTA: true
        },
        PODCAST: {
          data: [getFeaturePodcastData(data)],
          showCTA: true
        },
        ARTICLE: getFeatureArticleData(data),
        FORM: { data: getFeaturedFormData(data) },
        POST: data
      }[type]),
    [data, type]
  );

  if (!Component || !componentProps) return null;

  return (
    <div className="post-instance">
      <Component {...componentProps} responsive />
    </div>
  );
}

function Actions({ isDeleted, id, removeItem }) {
  const ref = useRef();
  const [updatePost, { loading }] = useMutation(UPDATE_POST, {
    onError() {
      if (ref.current) {
        ref.current.checked = !ref.current.checked;
      }
    },
    onCompleted(response) {
      if (response?.updatePostAdmin && isFunction(removeItem)) {
        removeItem(id);
      }
    }
  });

  const handleChange = (value) => {
    const {
      target: { checked: checkedValue }
    } = value;
    updatePost({
      variables: {
        where: { id },
        data: { isDeleted: checkedValue }
      }
    });
  };

  return (
    <div className="action">
      <CheckBox
        disabled={loading}
        ref={ref}
        defaultChecked={isDeleted}
        onChange={handleChange}
        label={
          <Flex align="center" gap={4}>
            Hidden
          </Flex>
        }
      />
    </div>
  );
}

function PostCard({
  createdBy,
  createdAt,
  text,
  flagCount,
  viewCount,
  prayerCount,
  likeCount,
  commentCount,
  attachment,
  moderationResponse,
  mediaModerationResponse,
  isDeleted,
  id,
  removeItem,
  instance,
  instanceType
}) {
  return (
    <div className="post-card">
      <Flex className="mb-8" gap={8} align="center">
        <Avatar src={createdBy?.profileImage}>
          {[createdBy?.firstName, createdBy?.lastName]
            .filter(Boolean)
            .map((name) => name[0]?.toUpperCase())
            .join('')}
        </Avatar>
        <Flex flex={1} vertical gap={2}>
          <p className="heading-h5">
            {createdBy?.firstName} {createdBy?.lastName}
          </p>
          <Tooltip title={moment(createdAt).format('DD[th] MMM, YYYY hh:mm A')}>
            <p className="created-at">{moment(createdAt).fromNow()}</p>
          </Tooltip>
        </Flex>
      </Flex>
      <div className="post-card-body">
        {text && <p className="mb-8">{text}</p>}
        {moderationResponse?.top?.class &&
          moderationResponse?.classes?.length > 0 && (
            <ModerationTags data={moderationResponse.classes} />
          )}
        <div className="attachment">
          {attachment && <Attachment {...attachment} />}
        </div>
        {mediaModerationResponse?.classes?.length > 0 && (
          <ModerationTags data={mediaModerationResponse.classes} />
        )}
        {instance && instanceType && (
          <PostInstance type={instanceType} data={instance} />
        )}
      </div>
      <Flex align="center" gap={8} className="stats-wrapper">
        <Flex align="center" flex={1} gap={8}>
          <Count icon={<EyeOutlined />} value={viewCount} />
          <Count icon={<LikeOutlined />} value={likeCount} />
          <Count
            icon={<CommentOutlined />}
            value={commentCount}
            {...(commentCount > 0 && {
              href: {
                pathname: ROUTES.COMMENTS,
                search: `?id=${id}&type=${COMMENTS_INSTANCE_TYPES.POST}`
              }
            })}
          />
          <Count icon={<FlagOutlined />} value={flagCount} />
          <Count icon={<HandsPraying />} value={prayerCount} />
        </Flex>
        <AccessControl
          modulePermissions={[
            {
              moduleKey: WORKSPACE_ROLE_PERMISSION.CONTENT_MANAGEMENT,
              allowedPermissions: [
                WORKSPACE_ROLE_LEVEL.EDIT,
                WORKSPACE_ROLE_LEVEL.DELETE
              ]
            }
          ]}
          showNoAccess={false}
        >
          <Actions isDeleted={isDeleted} id={id} removeItem={removeItem} />
        </AccessControl>
      </Flex>
    </div>
  );
}

export default PostCard;

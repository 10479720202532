import React, { createContext, useEffect, useState } from 'react';
import { io } from 'socket.io-client';
import { useApp } from './AppContext';
import { toast } from './apollo';

const SocketContext = createContext();

export const SOCKET_EVENTS = {
  GLOBAL_ADMIN_ALERTS: 'GLOBAL_ADMIN_ALERTS'
};

function SocketContextProvider({ children }) {
  const [socket, setSocket] = useState(null);

  const {
    state: { authToken, workspace }
  } = useApp();

  const workspaceId = workspace?.value;

  useEffect(() => {
    if (authToken && workspaceId) {
      const instance = io(process.env.REACT_APP_WEB_SOCKET_URL, {
        auth: {
          token: `Bearer ${authToken}`
        },
        extraHeaders: {
          'x-workspace-id': workspaceId
        }
      });

      instance.on('connect', () => {
        // Used console here to check socket connection
        // eslint-disable-next-line no-console
        console.log('Socket connected!');

        setSocket(instance);
      });

      instance.on('connect_error', (error) => {
        // Used console here to check socket connection
        // eslint-disable-next-line no-console
        console.log(`Socket connection failed ${error}`);

        instance?.disconnect();
      });

      return () => {
        instance?.disconnect();
      };
    }
  }, [authToken, workspaceId]);

  useEffect(() => {
    const alertsHandler = ({ type, message } = {}) => {
      toast({
        type,
        message
      });
    };

    socket?.on(SOCKET_EVENTS.GLOBAL_ADMIN_ALERTS, alertsHandler);

    return () => socket?.off(SOCKET_EVENTS.GLOBAL_ADMIN_ALERTS, alertsHandler);
  }, [socket]);

  return (
    <SocketContext.Provider value={{ socket }}>
      {children}
    </SocketContext.Provider>
  );
}

export default SocketContextProvider;
